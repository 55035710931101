<template>
  <div class="rf-container">
    <div class="rf-head">
      <login-form-head></login-form-head>
    </div>
    <div class="rf-main">
      <register-form></register-form>
    </div>
    <floot-nav :navColor="$route.meta.navColor"></floot-nav>
    <div class="ad-content">
      <img src="../../assets/index/ad.png" style="width: 100%;margin-bottom: -5px;"/>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/register-form/register-form'
import LoginFormHead from '@/components/login-form-head/login-form-head'
import flootNav from '@/components/flootNav/flootNav.vue'
export default {
  name: 'register',
  components: { LoginFormHead, flootNav, RegisterForm },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.rf-container {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}

.rf-main {
  width: 100%;
  height: 100%;
  margin: 50px 0;
  display: flex;
  justify-content: center;
}
.ad-content {
  position: fixed;
  bottom: 0;
  margin: 0;
  padding: 0;
}
</style>
