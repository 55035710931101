<template>
  <div class="rf-body">
    <el-row>
      <el-col>
        <div class="rf-title">
          <span><strong>新用户注册</strong></span>
        </div>
      </el-col>
      <el-col>
        <div class="content-panel">
          <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
            <el-form-item prop="userName">
              <el-input placeholder="请输入用户名（支持中文、英文、数字，4-20个字符）" v-model="registerForm.userName"></el-input>
            </el-form-item>
            <el-form-item prop="phoneNo">
              <el-input placeholder="请输入手机号码" v-model="registerForm.phoneNo"></el-input>
            </el-form-item>
            <el-form-item style="border: 1px solid #DCDFE6;border-radius: 4px;">
              <drag-verify
                ref="dragVerify"
                v-model:isPassing="isPassing"
                :width="365"
                text="请拖动滑块到最右边"
                successText="验证通过"
                background="#ccc"
                completedBg="rgb(0, 255, 0)"
                handlerIcon="el-icon-d-arrow-right"
                successIcon="el-icon-circle-check"
                @passcallback="passCallback"
              >
              </drag-verify>
            </el-form-item>
            <el-form-item prop="code">
              <el-input placeholder="短信验证码" v-model="registerForm.code">
                <template #append>
                  <el-link type="primary" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
                    获取验证码
                  </el-link>
                  <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                                 @end="onCountdownEnd">
                    （{{ seconds }} 秒）
                  </vue-countdown>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入密码，8-20位数字、字母和特殊字符组合" show-password v-model="registerForm.password"></el-input>
            </el-form-item>
            <el-form-item prop="confirmPass">
              <el-input placeholder="请再次输入密码" show-password v-model="registerForm.confirmPass"></el-input>
            </el-form-item>
            <el-form-item prop="isAgree">
              <div class="rf-agreement">
                <el-checkbox v-model="registerForm.isAgree"></el-checkbox><span style="margin-left: 10px;">我已阅读并同意E查工程<span @click="agreementClick()" style="color: #5D6FE9;cursor: pointer;">《隐私政策》</span></span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="rf-confirm" :disabled="!registerForm.isAgree" @click="handleConfirm">立即注册</el-button>
            </el-form-item>
            <div class="rf-return">
              <el-link href="/#/login" :underline="false" type="primary">返回登录</el-link>
            </div>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
import VueCountdown from '@chenfengyuan/vue-countdown'
import DragVerify from '../vue-drag-verify/dragVerify'

export default {
  name: 'register-form',
  components: { VueCountdown, DragVerify },
  data () {
    var validateUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空！'))
      }
      if (value.length < 0 || value.length > 20) {
        callback(new Error('请输入4-20位中文、英文、数字用户名！'))
      } else {
        callback()
      }
      const regex = /[ ,\\`,\\~,\\!,\\@,\\#,\\$,\\%,\\^,\\+,\\*,\\&,\\\\,\\/,\\?,\\|,\\:,\\.,\\<,\\>,\\{,\\},\\(,\\),\\'',\\;,\\=,\\"]/g
      if (regex.test(this.registerForm.userName)) {
        this.registerForm.userName = this.registerForm.userName.replace(regex, '')
      }
    }
    var validatePhoneNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空！'))
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        // const patrn = /^.*(?=.{6,20})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/
        const patrn = /^((\w)|[!@#$%^&*?]){6,20}$/
        if (!patrn.exec(value)) {
          return callback(new Error('只能输入6-20个字母、数字、特殊字符（!@#$%^&*）'))
        }
        if (this.registerForm.confirmPass !== '') {
          this.$refs.registerForm.validateField('confirmPass')
        }
        callback()
      }
    }
    var validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      registerForm: {
        userName: '',
        phoneNo: '',
        code: '',
        password: '',
        confirmPass: '',
        isAgree: false
      },
      registerRules: {
        userName: [{
          required: true,
          validator: validateUserName,
          trigger: 'change'
        }],
        phoneNo: [
          {
            required: true,
            validator: validatePhoneNo,
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入手机验证码！',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'change'
          },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirmPass: [
          {
            required: true,
            validator: validateConfirmPass,
            trigger: 'change'
          }
        ]
      },
      time: 60 * 1000,
      counting: false, // 倒计时
      isPassing: false, // 滑动验证是否通过
      isCodeEnable: false // 验证码获取功能是否可用
    }
  },
  methods: {
    handleConfirm () {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          const data = {
            dateBackgroundUrl: '/user/user/userRegister',
            dateBackgroundRequestType: 'post',
            data: {
              mobile: this.registerForm.phoneNo,
              password: this.registerForm.password,
              verification: this.registerForm.code,
              loginName: this.registerForm.userName,
              enterpriseTypeCode: 1
            }
          }
          forwardBID(data)
            .then(result => {
              if (result.code === 0) {
                ElMessage.success('注册成功！')
                this.$store.dispatch('logout')
                this.$router.push({ name: 'Login' })
              } else if (result.code === 1) {
                this.$router.push({ name: 'Login' })
                ElMessage.error(result.msg)
              } else {
                ElMessage.error(result.msg)
              }
            })
            .catch(error => {
              console.log(error)
              ElMessage.error('注册失败！')
            })
        }
      })
    },
    getVerifyCode () {
      console.log('this.registerForm.phoneNo: ' + this.registerForm.phoneNo)
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.registerForm.phoneNo,
          verifyTypeCode: '32'
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    onCountdownEnd () {
      this.time = 60 * 1000
      // this.isPassing = false
      this.counting = false
      this.isCodeEnable = true
    },
    startCountdown () {
      this.$refs.registerForm.validateField('phoneNo', (error) => {
        if (!error) {
          this.isCodeEnable = false
          this.counting = true
          this.getVerifyCode()
        }
      })
    },
    passCallback () {
      this.isCodeEnable = true
    },
    agreementClick () {
      const vm = this
      sessionStorage.removeItem('about-item')
      sessionStorage.setItem('about-item', 'privacyPolicy')
      const routeData = vm.$router.resolve({
        name: 'about'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.rf-body {
  padding: 0;
  display: flex;
  justify-content: center;
  /*border: 1px solid #ddd;*/
  /*border-radius: 8px;*/
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
  width: 365px;
  height: 600px;
  background: #fff;
}

.rf-title {
  font-size: 18px;
  color: #666666;
  margin: 20px;
}

.rf-confirm {
  width: 100%;
}

.rf-agreement {
  display: flex;
  justify-content: flex-start;
}

.rf-return {
  display: flex;
  justify-content: flex-start;
}
</style>
